import React from 'react'
import Layout from 'src/components/layout/layout'
import SEO from 'src/components/seo'
import { usePrivateRoute } from 'src/utils/auth'
import { RouteComponentProps } from '@reach/router'
import { Router } from '@reach/router'
import ScorecardTemplatesDetails from 'src/components/scorecard-templates-details/scorecard-templates-details'

const ScorecardTemplatePage = () => {
  usePrivateRoute()
  return (
    <Router basepath="/tournaments/settings/scorecard-templates/edit">
      <ScorecardTemplateRoute path="/:templateId" />
    </Router>
  )
}

interface ScorecardTemplateRouteProps extends RouteComponentProps {
  templateId?: string
}

const ScorecardTemplateRoute: React.FC<ScorecardTemplateRouteProps> = ({ templateId }) => {
  return (
    <Layout>
      <SEO title="Edit Scorecard Templates" />
      <ScorecardTemplatesDetails templateId={templateId} />
    </Layout>
  )
}

export default ScorecardTemplatePage
